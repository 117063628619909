import { Box, Button, Chip, Paper, Stack, Typography, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GridToolbarExportContainer, getGridStringOperators } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useEffect, useState, useCallback, useContext } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { userApi } from "../../store/user/userList";
import { ThemeContext } from '../../context/ThemeContext';
import DeleteUser from "./DeleteUser";
import UserStatus from "./UserStatus";
import BackdropUI from "../../ui/Backdrop";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";

export default function ManageUser() {
  const user = useSelector((state) => state?.user);
  const { result, loading, total } = user;

  const [userDetails, setUserDetails] = useState(null);
  const dispatch = useDispatch();
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [queryOptions, setQueryOptions] = useState({});
  const [isDownload, setIsDownload] = useState(false)

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const [selectedRow, setSelectedRow] = useState([]);
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);

  const columns = [
    {
      field: "username",
      headerName: "User Name",
      width: 150,
      sortable: false,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
    },
    {
      field: "phone_number",
      sortable: false,
      headerName: "Phone Number",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      width: 200,
    },
    {
      field: "email",
      sortable: false,
      headerName: "Email Address",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      width: 200,

    },
    {
      field: "gender",
      sortable: false,
      filterable: false,
      headerName: "Gender",
      width: 150,
      renderCell: (params) => (
        <>
          <Typography sx={{ textTransform: "capitalize" }}>{params?.row?.gender}</Typography>
        </>)
    },
    {
      field: "user_type",
      filterable: false,
      sortable: false,
      headerName: "User Type",
      width: 150,
      renderCell: (params) => (
        <>
          <Typography sx={{ textTransform: "capitalize" }}>{params?.row?.user_type}</Typography>
        </>)
    },
    {
      field: "pincode",
      sortable: false,
      headerName: "PinCode",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals',
      ),
      width: 150,
    }, {
      field: "is_active",
      filterable: false,
      sortable: false,
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <>
          {params?.row?.is_active ? (
            <Chip color="success" label="Active" variant="outlined" onClick={() => {
              setNotSelectedProductModel(true)
              setUserDetails(params.row)
            }} />
          ) : (
            <Chip color="error" label="InActive" variant="outlined" onClick={() => {
              setNotSelectedProductModel(true)
              setUserDetails(params.row)
            }} />
          )}
        </>
      ),
    }, {
      field: "created_at",
      sortable: true,
      filterable: false,
      headerName: "Registration Date",
      width: 150,
    },
  ];
  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const sortModel = queryOptions?.sortModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;
    let query
    if (sortModel?.length > 0) {
      query = {
        page: paginationModel?.page + 1,
        page_size: paginationModel?.pageSize,
        sort_order: sortModel[0].sort || "desc",
        field: firstItem.field || '',
        operator: firstItem.operator || (quickFilterValues && "contains") || '',
        value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
      };
    } else {
      query = {
        page: paginationModel?.page + 1,
        page_size: paginationModel?.pageSize,
        sort_order: "desc",
        field: firstItem.field || '',
        operator: firstItem.operator || (quickFilterValues && "contains") || '',
        value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
      };
    }
    dispatch(userApi(query));
  }, [dispatch, queryOptions, paginationModel]);

  const handleSortModelChange = useCallback((sortModel) => {
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>


        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Users List</Typography>
        </Stack>


        <Paper elevation={0}>
          <DataGrid
            loading={loading}
            rowCount={total}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            paginationMode="server"
            sx={{ height: 500 }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            filterMode="server"
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "userList", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.userList })),
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>
      {/* 
      <DeleteUser
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        setSelectedRow={setSelectedRow}
      /> */}
      <BackdropUI open={isDownload} />

      <UserStatus
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        details={userDetails}
      />
    </>
  );
}
